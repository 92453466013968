import React, { useState } from "react";

import Chatbot from "./Chatbot"; // Importing the Chatbot component
import styles from "./chatbot.module.css"; // Importing styles
import { RxCross2 } from "react-icons/rx"; // Importing cross icon
import { chatdenhan } from "../../../constants/images"; // Importing image

const ChatBotDenhanIndex = ({ chatId2, userImage, connectToSocket, socketNewMessage, isChatClose, clearSocketState, template, apiBaseUrl, isIconsetVisible, setIconsetVisible }) => {

  // State to manage the visibility of the first message notification
  const [isFirstMessageVisible, setisFirstMessageVisible] = useState(true);

  // Function to toggle the visibility of the chat icon
  const toggleIconset = () => {
    // Set the visibility of the first message notification to true
    setisFirstMessageVisible(true);
    // Toggle the visibility of the chat icon
    setIconsetVisible((prev) => !prev);
    // Clear socket state
    clearSocketState();
  };

  return (
    <div style={{ zIndex: "999998", position: "fixed" }} >
      <div className={styles.full_chatbot}>
        {/* Render the Chatbot component if the chat icon is visible */}
        {isIconsetVisible && (
          <Chatbot
            chatId2={chatId2}
            userImage={userImage}
            onClose={toggleIconset}
            connectToSocket={connectToSocket}
            socketNewMessage={socketNewMessage}
            isChatClose={isChatClose}
            template={template}
            apiBaseUrl={apiBaseUrl}
          />
        )}
        <div className={styles.iconset} >
          {/* Render the chat icon if it is not visible */}
          {!isIconsetVisible && (
            <div className={styles.chat_initiate}>
              {/* Render first message notification if it is visible */}
              {isFirstMessageVisible && <div className={styles.notification_wrap}>
                {/* Cross icon to close the notification */}
                <div onClick={() => setisFirstMessageVisible(false)} className={styles.greeting_action}><RxCross2 /></div>
                {/* Message greeting */}
                <div onClick={toggleIconset} className={styles.greering_message}>Hello, how can we help?</div>
              </div>}
              {/* Chat icon */}
              <img onClick={toggleIconset} src={chatdenhan} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatBotDenhanIndex;
