import React, { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { v4 as uuidv4 } from "uuid";
import { FaAngleDown, FaEllipsisV } from "react-icons/fa";

import styles from "./chatbot.module.css";
import ChatbotMessageSend from "./ChatbotMessageSend";
import ChatbotMessages from "./ChatbotMessages";
import Loader from "../../customComponents/Loader";
import useChatMessages from "../hooks/useChatMessages";
import { chatImgmagicbrick, logo } from "../../../constants/images";

// Chatbot component responsible for managing the chat interface
export default function Chatbot({ chatId2, onClose, connectToSocket, socketNewMessage, isChatClose, template, apiBaseUrl }) {

  // Custom hook for managing chat messages and state
  const { currentChatId, hasChatCreated, createType, createChat, messages, setMessages, loading, chatLoading,
    setchatLoading, isBotWorking, setIsBotWorking, chatClose, setChatClose, handleCloseChat, ValidateKey, handleRating, handleEmojiSelect,
    onChatMsgSubmit, handleSendDailogApi, chatBotAdminData, isAddress, stopSendloading, range, maxRange} = useChatMessages(localStorage?.getItem(`chat-session-Id-${chatId2}`) || uuidv4(), apiBaseUrl);

  // State and functions for managing popover
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rangeData, setRangeData] = useState(0);
  const [captureFieldError, setCaptureFieldError] = useState("");
  const [captureFieldValue, setCaptureFieldValue] = useState("");
  
  const handleClick = (event) => { setAnchorEl(event.currentTarget); };
  const handleClose = () => { setAnchorEl(null); };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Validate chat session key on chatId change
  useEffect(() => {
    if (chatId2) { ValidateKey(chatId2); }
  }, [chatId2]);

  // Connect to socket when chat session is created or updated
  useEffect(() => {
    if (currentChatId) {
      console.log("Connecting on API Response", currentChatId);
      connectToSocket(currentChatId);
    } else if (chatId2) {
      console.log("Connect the socket on page load");
      const chatDataStr = localStorage.getItem(chatId2);
      if (chatDataStr) {
        const chatBotConnectid = JSON.parse(chatDataStr)._id;
        connectToSocket(chatBotConnectid);
      }
    } else {
      console.log("Error count not connect the socket.");
    }
  }, [hasChatCreated, currentChatId]);

  // Handle incoming socket messages
  useEffect(() => {
    if (socketNewMessage) {
      if (isChatClose || socketNewMessage?.userMessage?.close) { setChatClose((prev) => isChatClose); }
      setMessages((prevMessages) => [...prevMessages, socketNewMessage?.userMessage || socketNewMessage?.userMessageNew,]);
      if (socketNewMessage?.userMessage?.close) {
        let userMessage = {
          message: "Please provide rating for this conversation.",
          type: "rating",
          options: ["Good", "Average", "Bad"],
          sender: "bot",
          field: "dailog_select",
          value: ""
        }
        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setChatClose((prev) => true);
      }
    }
  }, [socketNewMessage]);

  return (
    <div className={styles.all_chatpart_design}>
      {/* Chat header */}
      <div className={styles.all_chatpart_header}>
        {loading ? <div className={styles.with_avtar}>
          <img src={chatBotAdminData?.chatBotData?.chatbotImage || chatImgmagicbrick} alt="" />
        </div> : <div className={styles.with_avtar}>
          <img src={chatBotAdminData?.chatBotData?.chatbotImage || chatImgmagicbrick} alt="" />
          {chatBotAdminData?.chatBotData?.chatbotName || "Hii there !"}
        </div>}
        {/* More options dropdown */}
        <div className={styles.more_Option}>
          <div onClick={handleClick} className={styles.donw_arrow}>
            <FaEllipsisV />
          </div>
          {/* Popover content */}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ zIndex: 999999 }}>
            <Typography
              sx={{ px: 2, py: 1, cursor: "pointer" }}
              onClick={() => handleCloseChat(chatId2, onClose, handleClose)} className={styles.close_chat}>
              Close Chat
            </Typography>
          </Popover>
          <div onClick={onClose} className={styles.donw_arrow}>
            <FaAngleDown />
          </div>
        </div>
      </div>
      {/* Online status */}
      <div className={styles.we_online_box}>
        <span className={styles.live_icon}></span>
        We are online
      </div>
      {/* Loading or chat messages */}
      {loading ? (
        <div className={styles.all_chatpart_body}>
          <Loader size="medium" />
        </div>
      ) : (
        <ChatbotMessages
          chatId={chatId2}
          setIsBotWorking={setIsBotWorking}
          messages={messages}
          chatLoading={chatLoading}
          setchatLoading={setchatLoading}
          setMessages={setMessages}
          setChatClose={setChatClose}
          handleSendDailogApi={handleSendDailogApi}
          createChat={createChat}
          onChatMsgSubmit={onChatMsgSubmit}
          createType={createType}
          chatBotAdminData={chatBotAdminData}
          stopSendloading={stopSendloading}
          onClose={onClose}
          chatClose={chatClose}
          handleRating={handleRating}
          template={template}
          apiBaseUrl={apiBaseUrl}
          range={range}
          maxRange={maxRange}
          setRangeData={setRangeData}
          rangeData={rangeData}
          setCaptureFieldError={setCaptureFieldError}
          setCaptureFieldValue={setCaptureFieldValue}
        />
      )}
      {/* Chat message input */}
      <ChatbotMessageSend
        onClose={onClose}
        messages={messages}
        isBotWorking={isBotWorking}
        chatClose={chatClose}
        chatId={chatId2}
        handleRating={handleRating}
        handleEmojiSelect={handleEmojiSelect}
        onChatMsgSubmit={onChatMsgSubmit}
        handleSendDailogApi={handleSendDailogApi}
        createChat={createChat}
        isAddress={isAddress}
        stopSendloading={stopSendloading}
        apiBaseUrl={apiBaseUrl}
        createType={createType}
        captureFieldError={captureFieldError}
        captureFieldValue={captureFieldValue}
        setCaptureFieldValue={setCaptureFieldValue}
        range={range}
        maxRange={maxRange}
        setRangeData={setRangeData}
        rangeData={rangeData}
      />
      {/* Powered by */}
      <div className={styles.powered_box}>
        Powered By <img src={logo} alt="" /> <span>PropertyJinni</span>
      </div>
    </div>
  );
}
