module.exports.isValidRating = (msg) => {
  // Convert the message to lowercase before comparing
  const lowerCaseMsg = msg.toLowerCase();
  // Check if the message is one of the valid ratings
  return (lowerCaseMsg === "good" || lowerCaseMsg === "bad" || lowerCaseMsg === "average");
};

// Wrap setTimeout in a Promise
module.exports.delayedExecution = () =>
  new Promise((resolve) => {
    const timeoutId = setTimeout(() => {
      resolve();
      clearTimeout(timeoutId); // Clear the timeout after execution
    }, 1000);
  });

module.exports.formatBotResponse = (text) => {
  return text.toString()
    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Replace ** for bold
    .replace(/^\d+\.\s/gm, '<li>')                  // Replace numbered items with <li>
    .replace(/^\t\* (.*?)(?=\n|$)/g, '<li>$1</li>') // Replace * for list items
    .replace(/(?:\r\n|\r|\n)/g, '<br>')             // Replace newlines with <br>
    .replace(/<li>(.*?)<\/li>/g, '<li>$1</li>');                       // Replace single newline with <br>
}