import { useState } from "react";
import moment from "moment";
import Axios from "../../../utils/axios";
import { formatBotResponse, isValidRating } from "../../../utils/validators";

const useChatMessages = (sessionId, apiBaseUrl) => {
  // State variables
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatLoading, setchatLoading] = useState(false);
  const [hasChatCreated, setChatCreated] = useState(false);
  const [currentChatId, setCurrentChatId] = useState("");
  const [isBotWorking, setIsBotWorking] = useState(true);
  const [chatClose, setChatClose] = useState(false);
  const [ratingData, setRatingData] = useState(false);
  const [createChat, setCreateChat] = useState(false);
  const [createType, setcreateType] = useState(false);
  const [chatBotAdminData, setchatBotAdminData] = useState();
  const [stopSendloading, setStopSendloading] = useState(false);
  const [isAddress, setIsAddress] = useState(false);
  const [range, setRange] = useState(false);
  const [maxRange, setMaxRange] = useState(false);

  // Handle closing the chat
  const handleCloseChat = (chatId, onClose, handleClose) => {
    let conversationData = localStorage.getItem(chatId);
    localStorage.removeItem(`chat-session-Id-${chatId}`);
    if (conversationData && !chatClose) {
      Axios.post(`${apiBaseUrl}/botChat/closeChat`, {
        chatConversationId: JSON.parse(conversationData)._id, owner: false,
      }).then((res) => {
        setChatClose(true);
        let message = {
          message: "Please provide rating for this conversation.",
          type: "rating",
          options: ["Good", "Average", "Bad"],
          sender: "bot",
          field: "dailog_select",
          value: "",
        };
        setMessages((prev) => [...prev, message]);
        handleClose();
      });
    } else {
      Axios.post(`${apiBaseUrl}/domainKey/deleteTempraryChat`, { sender: sessionId })
        .then((res) => { console.log('Deleted successfully.'); })
        .catch((err) => { console.log(err); })

      onClose();
    }
  };

  // Create chat messages
  const createChatMessages = (chatId) => {
    setchatLoading(true);
    Axios.post(`${apiBaseUrl}/aiChatbot/createAiChatbotFlow`, { encryptedKey: chatId, sender: sessionId, message: "hi" })
      .then(async (res) => {
        localStorage.setItem(`chat-session-Id-${chatId}`, sessionId)

        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", type: "message", message: formatBotResponse(res?.data?.data?.out), },
        ]);

        setchatLoading(false);
      });
  }

  // Validate chat key
  const ValidateKey = (chatId) => {
    setLoading(true);
    Axios.post(`${apiBaseUrl}/domainKey/validateKey`, { encryptedKey: chatId })
      .then((resCompanyData) => {
        setLoading(false);
        setIsBotWorking(true);
        setchatBotAdminData((prev) => resCompanyData.data.data);

        if (localStorage.getItem(chatId)) {
          let conversationData = localStorage.getItem(chatId);
          Axios.get(`${apiBaseUrl}/botChat/getChatBotMessage?conversationId=${JSON.parse(conversationData)._id}`)
            .then((response) => {
              if (response?.data?.data?.isActive) {
                setMessages(response?.data?.data?.chatMessages);
                setCreateChat(true);
                setCurrentChatId((prev) => response?.data?.data?._id);
              } else {
                localStorage.removeItem(chatId);
                Axios.post(`${apiBaseUrl}/domainKey/getTemporaryChat`, { encryptedKey: chatId, sender: sessionId })
                  .then((res) => {

                    if (res.data?.data?.questions?.length > 0) {

                      let modifiedQuestions = res.data?.data?.questions.map(question => {
                        return question.sender === resCompanyData?.data?.data?.userId ? question : { ...question, message: formatBotResponse(question?.message), sender: null };
                      });

                      for (let i = 0; i < modifiedQuestions.length; i++) {

                        if (modifiedQuestions[i].type === "question" || modifiedQuestions[i]?.type === "capture-visitor" || modifiedQuestions[i]?.type === "capture-property") {
                          modifiedQuestions[i] = {
                            ...modifiedQuestions[i], options: modifiedQuestions[i].options?.map((data1) => {
                              return `${data1?.nodeDesc}`;
                            }), value: (modifiedQuestions[i + 1]?.message || ""), field: "dailog_select"
                          };
                          setMessages((prev) => [...prev, modifiedQuestions[i]]);
                        }
                        else if (modifiedQuestions[i - 1]?.type === "question" || modifiedQuestions[i - 1]?.type === "capture-visitor" || modifiedQuestions[i - 1]?.type === "capture-property") {
                          setMessages(prev => prev);
                        }
                        else {
                          setMessages((prev) => [...prev, modifiedQuestions[i]]);
                        }

                      }

                      if (res.data?.data?.questions[res.data?.data?.questions?.length - 1]?.rangeValue) {
                        setRange(res.data?.data?.questions[res.data?.data?.questions?.length - 1]?.range);
                        setMaxRange(res.data?.data?.questions[res.data?.data?.questions?.length - 1]?.rangeValue);
                      }
                      else if (res.data?.data?.questions[res.data?.data?.questions?.length - 1].type === 'FLAG_Capture_Location') {
                        setIsAddress(true)
                        setMessages((prev) => [...prev.slice(0, prev.length - 1), { ...res.data?.data?.questions[res.data?.data?.questions?.length - 1], category: res.data?.data?.customerType }]);
                      }
                      else if (res.data?.data?.questions[res.data?.data?.questions?.length - 1].type === "FLAG_Capture_Date_Meeting") {
                        setcreateType(res.data?.data?.questions[res.data?.data?.questions?.length - 1].type);

                      }

                    } else {
                      createChatMessages(chatId)
                    }
                  })
                  .catch(((err) => {
                    createChatMessages(chatId)
                  }))
              }
            })
            .catch((error) => {
              setchatLoading(false);
            });
        } else {
          Axios.post(`${apiBaseUrl}/domainKey/getTemporaryChat`, { encryptedKey: chatId, sender: sessionId })
            .then((res) => {

              if (res.data?.data?.questions?.length > 0) {

                let modifiedQuestions = res.data?.data?.questions.map(question => {
                  return question.sender === resCompanyData?.data?.data?.userId ? question : { ...question, sender: null };
                });

                for (let i = 0; i < modifiedQuestions.length; i++) {

                  if (modifiedQuestions[i].type === "question" || modifiedQuestions[i]?.type === "capture-visitor" || modifiedQuestions[i]?.type === "capture-property") {
                    modifiedQuestions[i] = {
                      ...modifiedQuestions[i], options: modifiedQuestions[i].options?.map((data1) => { return `${data1?.nodeDesc}`; }),
                      value: modifiedQuestions[i + 1]?.message || "", field: "dailog_select"
                    };
                    setMessages((prev) => [...prev, modifiedQuestions[i]]);
                  }
                  else if (modifiedQuestions[i - 1]?.type === "question" || modifiedQuestions[i - 1]?.type === "capture-visitor" || modifiedQuestions[i - 1]?.type === "capture-property") {
                    setMessages(prev => prev);
                  }
                  else {
                    setMessages((prev) => [...prev, modifiedQuestions[i]]);
                  }

                }

                if (res.data?.data?.questions[res.data?.data?.questions?.length - 1]?.rangeValue) {
                  setRange(res.data?.data?.questions[res.data?.data?.questions?.length - 1]?.range);
                  setMaxRange(res.data?.data?.questions[res.data?.data?.questions?.length - 1]?.rangeValue);
                }
                else if (res.data?.data?.questions[res.data?.data?.questions?.length - 1].type === 'FLAG_Capture_Location') {
                  setIsAddress(true)
                  setMessages((prev) => [...prev.slice(0, prev.length - 1), { ...res.data?.data?.questions[res.data?.data?.questions?.length - 1], category: res.data?.data?.customerType }]);
                }
                else if (res.data?.data?.questions[res.data?.data?.questions?.length - 1].type === "FLAG_Capture_Date_Meeting"
                ) {
                  setcreateType(res.data?.data?.questions[res.data?.data?.questions?.length - 1].type);
                }
              }
              else {
                createChatMessages(chatId)
              }
            })
            .catch(((err) => {
              createChatMessages(chatId)
            }))
        }
      })
      .catch((err) => {
        setchatLoading(false);
        setLoading(false);
        setIsBotWorking(false);
        localStorage.removeItem(chatId);
        localStorage.removeItem(`chat-session-Id-${chatId}`);
        setMessages([{ sender: "bot", type: "message", message: "Bot could not be connected.", },]);
      });
  };

  // Save chat rating
  const handleSaveRating = (isFormAlreadySubmitted, rating) => {
    const ratingValue = rating === "good" ? 3 : rating === "average" ? 2 : 1;
    setchatLoading(true);
    const data = { rating: ratingValue, against: isFormAlreadySubmitted._id, user: isFormAlreadySubmitted.user_id, reviewFor: "chat bot", };

    Axios.post(`${apiBaseUrl}/review/addReview`, data).then((res) => {
      setRatingData(res.data.data);
      setchatLoading(false);
      setMessages((prev) => [
        ...prev.slice(0, prev.length),
        { sender: "bot", type: "review", message: "Please provide your review about our services.", },
      ]);
    }).catch((err) => {
      setchatLoading(false);
    });
  };

  // updating review 
  const handlepUpdateReview = (review, onClose, chatId) => {
    if (!review.trim()) { return; }

    localStorage.removeItem(`chat-session-Id-${chatId}`);
    let data = { reviewId: ratingData._id, comment: review, };
    setchatLoading(true);

    Axios.post(`${apiBaseUrl}/review/updateReview`, data).then((res) => {
      setIsBotWorking(false);
      setchatLoading(false);
      onClose();
      localStorage.removeItem(chatId);
    }).catch((err) => {
      setchatLoading(false);
    });;
  };

  // updating rating
  const handleRating = (msg, setMsg, onClose, isFormAlreadySubmitted, chatId) => {
    if (!msg.trim()) { return; }

    if (messages[messages.length - 1].type === "rating") {

      if (isValidRating(msg)) {
        setMessages((prev) => [...prev.slice(0, prev.length), { sender: "you", type: "message", message: msg, },]);
        handleSaveRating(isFormAlreadySubmitted, msg.toLowerCase(), msg);
      } else {
        setMessages([
          ...messages.slice(0, messages.length),
          { sender: "you", type: "message", message: msg, },
          {
            sender: "bot",
            type: "rating",
            options: ["Good", "Average", "Bad"],
            message: "You can only provide rating.",
            field: "dailog_select",
            value: "",
          },
        ]);
      }
      setMsg("");
    } else {
      handlepUpdateReview(msg, onClose, chatId);
      setMessages([
        ...messages.slice(0, messages.length),
        { sender: "you", type: "message", message: msg, },
        { sender: "bot", type: "review", message: "Thankyou for sharing your review.", },
      ]);
      setMsg("");
    }
  };

  // updating chat messages
  const updateChat = (isFormAlreadySubmitted, msg) => {
    setMessages([...messages, { sender: isFormAlreadySubmitted?._id, message: msg },]);
  };

  // send chat messages
  const onChatMsgSubmit = (e, msg, chatId, isFormAlreadySubmitted, setMsg) => {

    if (!msg.trim()) { return; }
    e.preventDefault();
    e.stopPropagation();
    setMsg("");
    setchatLoading(true);
    const data = { encryptedKey: chatId, chatId: isFormAlreadySubmitted._id, message: msg, };

    Axios.post(`${apiBaseUrl}/botChat/sendChatBotMessage`, data)
      .then((res) => {
        // Assuming the response contains the bot's message
        const botMessage = res.data.data.message;
        updateChat(isFormAlreadySubmitted, botMessage);
        setchatLoading(false);
      })
      .catch((err) => {
        setchatLoading(false);
      });
  };

  // sumbit chat flow questions
  const handleSendDailogApi = (msg, chatId, time = false, fullAddress, setFullAddress) => {
    if (!msg.trim()) { return; }

    setStopSendloading(true);
    setchatLoading(true);
    setIsAddress(false);
    setRange(false);
    setMaxRange(0);
    setcreateType("");
    setMessages((prevMessages) => [
      ...prevMessages.slice(0, prevMessages.length - ((prevMessages[prevMessages.length - 1].type === "question" || prevMessages[prevMessages.length - 1].type === "capture-visitor" || prevMessages[prevMessages.length - 1].type === "capture-property") ? 1 : 0)),
      (prevMessages[prevMessages.length - 1].type === "question" || prevMessages[prevMessages.length - 1].type === "capture-visitor" || prevMessages[prevMessages.length - 1].type === "capture-property")
        ? { ...prevMessages[prevMessages.length - 1], value: msg, }
        : { sender: "you", type: "message", field: "message", message: time ? moment(msg).format("MMMM Do [at] h:mm A") : msg, },
    ]);

    setchatLoading(true);
    Axios.post(`${apiBaseUrl}/aiChatbot/createAiChatbotFlow`, { encryptedKey: chatId, sender: sessionId, message: msg, fullAddress: JSON.stringify(fullAddress), isTime: time })
      .then(async (res) => {
        setStopSendloading(false);
        let incomingMessage = { sender: "bot", type: "message", message: formatBotResponse(res?.data?.data?.out), };


        if (res?.data?.data?.flag === "FLAG_Capture_Location") {
          setIsAddress(true);
          if (Array.isArray(res?.data?.data?.extra)) {
            // If data.extra is an array, set customerType to the first element or undefined if the array is empty
            incomingMessage.category = res?.data?.data?.extra?.length > 0 ? res?.data?.data?.extra?.extra[0] : "seller"
          } else {
            // If data.extra is not an array, set customerType directly to data.extra
            incomingMessage.category = res?.data?.data?.extra
          }
        } else if (res?.data?.data?.flag === "FLAG_Capture_Date_Meeting") {
          setcreateType(true);
          incomingMessage.category = res?.data?.data?.extra[1] + "-" + res?.data?.data?.extra[2]
          incomingMessage.postCode = res?.data?.data?.extra[0]
        }

        setMessages((prevMessages) => [
          ...prevMessages,
          incomingMessage
        ]);

        setchatLoading(false);


        let aiChatId = res?.data?.data?.extra[0]?.replaceAll(`"`, ``);


        if (res?.data?.data?.flag === "FLAG_End_Chat") {

          setcreateType("");
          setStopSendloading(false);
          localStorage.removeItem(`chat-session-Id-${chatId}`);

          if (!aiChatId) {
            setIsBotWorking(false);
            setChatClose(true);
          } else {
            setIsBotWorking(true);
            setChatClose(false);
          }
        }

        if (aiChatId && res?.data?.data?.flag === "FLAG_End_Chat") {
          Axios.get(`${apiBaseUrl}/botChat/getChatBotMessage?conversationId=${aiChatId}`)
            .then((response) => {
              if (aiChatId) {
                setCurrentChatId((prev) => aiChatId);
              }
              setCreateChat(true);
              setMessages(response?.data?.data?.chatMessages);
              setchatLoading(false);
              localStorage.setItem(chatId, JSON.stringify({ _id: aiChatId, user_id: response?.data?.data?.chatUserMetaData, }));
            }).catch(() => {
              setIsBotWorking(false);
              setChatClose(true);
            });
          setchatLoading(false);
          setChatCreated((prev) => true);
        }

      }).catch((err) => {
        setchatLoading(false);
      }).finally(() => {
        if (fullAddress) {
          setFullAddress();
        }
      });
  };

  // add emoji in messages
  const handleEmojiSelect = (emoji, textareaRef, setMsg, handleClose) => {
    const cursorPos = textareaRef.current.selectionStart;

    setMsg((prevMsg) => {
      const start = prevMsg.substring(0, cursorPos);
      const end = prevMsg.substring(cursorPos);
      return `${start}${emoji.native}${end}`;
    });
    handleClose();
  };

  // Return necessary functions and states from the custom hook
  return {
    stopSendloading,
    createType,
    messages,
    loading,
    chatLoading,
    isBotWorking,
    ratingData,
    chatClose,
    createChat,
    hasChatCreated,
    currentChatId,
    chatBotAdminData,
    isAddress,
    setMessages,
    setLoading,
    setchatLoading,
    setIsBotWorking,
    setRatingData,
    setChatClose,
    handleCloseChat,
    ValidateKey,
    handleRating,
    handlepUpdateReview,
    handleSaveRating,
    handleEmojiSelect,
    onChatMsgSubmit,
    handleSendDailogApi,
    range,
    maxRange
  };
};
export default useChatMessages;
