import React from "react";

// Importing the Chatbot component and some styles
import Chatbot from "./Chatbot";
import styles from "./chatbot.module.css";
// Importing images used in the component
import { chatImgCharles, chatArrow } from "../../../constants/images";


// ChatBotBtcIndex component definition
const ChatBotCharlessinclairIndex = ({ chatId2, isIconsetVisible, setIconsetVisible, userImage, connectToSocket, socketNewMessage, isChatClose, clearSocketState, template, apiBaseUrl }) => {

  // Function to toggle the visibility of the chat icon
  const toggleIconset = () => {
    // Toggle the state to show/hide the chat icon
    setIconsetVisible((prev) => !prev);
    // Clear the socket state
    clearSocketState();
  };

  return (
    <div style={{ zIndex: "999998", position: "fixed" }}>
      <div className={styles.full_chatbot}>
        {/* Render the Chatbot component if the chat icon is visible */}
        {isIconsetVisible && (
          <Chatbot
            chatId2={chatId2}
            userImage={userImage}
            onClose={toggleIconset}
            connectToSocket={connectToSocket}
            socketNewMessage={socketNewMessage}
            isChatClose={isChatClose}
            template={template}
            apiBaseUrl={apiBaseUrl}
          />
        )}
        {/* Chat icon */}
        <div className={styles.iconset} onClick={toggleIconset}>
          {/* Render different views based on chat icon visibility */}
          {isIconsetVisible && (
            <div className={styles.agent_icon_collapsed}>
              {/* Render collapsed chat icon */}
              <img src={chatImgCharles} alt="Chat icon" />
            </div>
          )}
          {!isIconsetVisible && (
            <div className={styles.chatfloat_icon}>
              {/* Render expanded chat icon */}
              <div className={styles.agent_icon}> <img src={chatImgCharles} alt="Chat icon" /> 
              <span className={styles.online_dot}> </span>
              </div>
              <div className={styles.agent_chatline}>Let's Chat</div>
              <div className={styles.arrow_wrapper}> <img src={chatArrow} alt="Arrow icon" /> </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatBotCharlessinclairIndex;
