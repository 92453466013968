import React, { useEffect, useState } from "react";
import styles from "./chatbot.module.css";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { v4 as uuidv4 } from "uuid";

// Importing child components and images
import ChatbotMessageSend from "./ChatbotMessageSend";
import ChatbotMessages from "./ChatbotMessages";
import Loader from "../../customComponents/Loader";
import useChatMessages from "../hooks/useChatMessages";
import { logo, liveIcon, option, drop, shape } from "../../../constants/images";

export default function Chatbot({ chatId2, onClose, connectToSocket, socketNewMessage, isChatClose, userImage, template, apiBaseUrl }) {
  // Destructure variables and functions from custom hook
  const { currentChatId, hasChatCreated, createType, createChat, messages, setMessages, loading, chatLoading,
    setchatLoading, isBotWorking, setIsBotWorking, chatClose, setChatClose, handleCloseChat, ValidateKey, handleRating, handleEmojiSelect,
    onChatMsgSubmit, handleSendDailogApi, chatBotAdminData, isAddress, stopSendloading, range, maxRange } = useChatMessages(localStorage?.getItem(`chat-session-Id-${chatId2}`) || uuidv4(), apiBaseUrl);

  // State for popover
  const [rangeData, setRangeData] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [captureFieldError, setCaptureFieldError] = useState("");
  const [captureFieldValue, setCaptureFieldValue] = useState("");
  // Open popover
  const handleClick = (event) => { setAnchorEl(event.currentTarget); };

  // Close popover
  const handleClose = () => { setAnchorEl(null); };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    // Validate chat key when chatId2 changes
    if (chatId2) { ValidateKey(chatId2); }
  }, [chatId2]);

  useEffect(() => {
    // Connect to socket when chatId2 or hasChatCreated changes
    if (currentChatId) {
      connectToSocket(currentChatId);
    } else if (chatId2) {
      const chatDataStr = localStorage.getItem(chatId2);
      if (chatDataStr) {
        const chatBotConnectid = JSON.parse(chatDataStr)._id;
        connectToSocket(chatBotConnectid);
      }
    }
  }, [hasChatCreated, currentChatId]);

  useEffect(() => {
    // Handle new messages from socket
    if (socketNewMessage) {
      if (isChatClose || socketNewMessage?.userMessage?.close) { setChatClose((prev) => isChatClose); }
      setMessages((prevMessages) => [...prevMessages, socketNewMessage?.userMessage || socketNewMessage?.userMessageNew,]);
      if (socketNewMessage?.userMessage?.close) {
        // Show rating prompt when conversation ends
        let userMessage = {
          message: "Please provide rating for this conversation.",
          type: "rating",
          options: ["Good", "Average", "Bad"],
          sender: "bot",
          field: "dailog_select",
          value: ""
        }
        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setChatClose((prev) => true);
      }
    }
  }, [socketNewMessage]);

  return (
    <div className={styles.all_chatpart_design}>
      {/* Header section */}
      <div className={styles.all_chatpart_header}>
        <div className="chat_close">
          <div onClick={onClose} className={styles.donw_arrow}>
            <img src={drop} alt="Close chat icon" />
          </div>
        </div>
        {!loading && (
          <div className={styles.with_avtar}>
            {chatBotAdminData?.chatBotData?.chatbotName || ""}
          </div>
        )}
        {/* More options popover */}
        <div className={styles.more_Option}>
          <div onClick={handleClick} className={styles.donw_arrow}>
            <img src={option} alt="More options icon" />
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ zIndex: 999999 }}>
            <Typography
              sx={{ px: 2, py: 1, cursor: "pointer" }}
              className={styles.close_text}
              onClick={() => handleCloseChat(chatId2, onClose, handleClose)}>
              Close Chat
            </Typography>
          </Popover>
        </div>
      </div>

      {/* Chat body */}
      <div className="chatpos_wrapper">
        <div className={styles.chat_shape}>
          <img src={shape} alt="Chat shape" />
        </div>
      </div>

      {/* Online status */}
      <div className={styles.online_pos}>
        <div className={styles.we_online_box}>
          <img src={liveIcon} alt="Live icon" /> Online
        </div>
      </div>

      {/* Render loading or chat messages */}
      {loading ? (
        <div className={styles.all_chatpart_body}>
          <Loader size="medium" />
        </div>
      ) : (
        <ChatbotMessages
          chatId={chatId2}
          setIsBotWorking={setIsBotWorking}
          messages={messages}
          chatLoading={chatLoading}
          setchatLoading={setchatLoading}
          setMessages={setMessages}
          setChatClose={setChatClose}
          handleSendDailogApi={handleSendDailogApi}
          createChat={createChat}
          onChatMsgSubmit={onChatMsgSubmit}
          createType={createType}
          chatBotAdminData={chatBotAdminData}
          stopSendloading={stopSendloading}
          onClose={onClose}
          chatClose={chatClose}
          handleRating={handleRating}
          template={template}
          apiBaseUrl={apiBaseUrl}
          range={range}
          maxRange={maxRange}
          setRangeData={setRangeData}
          rangeData={rangeData}
          setCaptureFieldError={setCaptureFieldError}
          setCaptureFieldValue={setCaptureFieldValue}
        />
      )}

      {/* Chat input */}
      <ChatbotMessageSend
        onClose={onClose}
        messages={messages}
        isBotWorking={isBotWorking}
        chatClose={chatClose}
        chatId={chatId2}
        handleRating={handleRating}
        handleEmojiSelect={handleEmojiSelect}
        onChatMsgSubmit={onChatMsgSubmit}
        handleSendDailogApi={handleSendDailogApi}
        createChat={createChat}
        isAddress={isAddress}
        stopSendloading={stopSendloading}
        apiBaseUrl={apiBaseUrl}
        createType={createType}
        captureFieldError={captureFieldError}
        captureFieldValue={captureFieldValue}
        setCaptureFieldValue={setCaptureFieldValue}
        range={range}
        maxRange={maxRange}
        setRangeData={setRangeData}
        rangeData={rangeData}
      />

      {/* Powered by */}
      <div className={styles.powered_box}>
        Powered By <img src={logo} alt="Propertyjinni logo" /> <span>Propertyjinni</span>
      </div>
    </div>
  );
}
