import { useState } from "react";
import Axios from "../../utils/axios";

const useBotData = (apiBaseUrl) => {
    const [waitingTime, setWaitingTime] = useState(0);
    const [isChatbotActive, setIsChatbotActive] = useState(false);
    const [chatbotFullData, setChatbotFullData] = useState(false);
    // get bot data
    const getChatbotData = (key) => {
        Axios.post(`${apiBaseUrl}/domainKey/validateKey`, { encryptedKey: key })
            .then((res) => {
                setWaitingTime(res.data?.data?.chatBotData?.waitingTime)
                setChatbotFullData(res.data?.data)
                setIsChatbotActive(true)
            })
            .catch((error) => {
                setWaitingTime(0);
                setIsChatbotActive(false)
            });
    }

    return {
        getChatbotData, chatbotFullData,
        waitingTime, isChatbotActive,
    }
}

export default useBotData