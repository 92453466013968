import React, { useEffect, useRef, useState } from "react";
import Popover from "@mui/material/Popover";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { FIELD_TYPE } from "../../../constants/formFields.js";
import styles from "./chatbot.module.css";
import useAddress from "../hooks/useAddressFilter.js";
import { sendIcondenhan, emoji } from "../../../constants/images.js";

// ChatbotMessageSend component for sending messages in chat
const ChatbotMessageSend = ({ messages, chatId, isBotWorking, chatClose, onClose, handleEmojiSelect, captureFieldError, captureFieldValue,
  onChatMsgSubmit, handleRating, handleSendDailogApi, createChat, isAddress, stopSendloading, apiBaseUrl, createType, range, rangeData, }) => {

  const [msg, setMsg] = useState(""); // State for storing message input
  const { onChangeAddress, placesData, showList, setShowList, handleSaveAddresswithpostCode, fullAddress, setFullAddress } = useAddress(apiBaseUrl); // Address hook
  const isFormAlreadySubmitted = JSON.parse(localStorage.getItem(chatId)); // Check if form already submitted
  const textareaRef = useRef(); // Reference to textarea input

  const [anchorEl, setAnchorEl] = React.useState(null); // State for Popover anchor
  const handleClick = (event) => {
    if (!range && (!FIELD_TYPE?.includes(messages?.[messages.length - 1]?.customFieldTitle?.fieldType))) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => { setAnchorEl(null); }; // Handle close of Popover

  const open = Boolean(anchorEl); // Check if Popover is open
  const id = open ? "simple-popover" : undefined; // Popover id

  // Handle key down event (e.g., Enter key pressed)
  function handleKeyDown(e) {
    // Check if Enter key is pressed and conditions are met to send message
    if (
      e.key === "Enter" &&
      isBotWorking &&
      (isAddress ? (isAddress && fullAddress) : true) &&
      (!captureFieldError) &&
      msg &&
      messages?.[messages.length - 1]?.field !== "dailog_select" &&
      !stopSendloading &&
      (createType !== "arrange-callback" && createType !== "property-viewing" && createType !== "schedule-valuation")
    ) {
      // Check if chat is closed for rating or sending normal message
      if (chatClose) {
        handleRating(msg, setMsg, onClose, isFormAlreadySubmitted, chatId);
      } else {
        if (!createChat) {
          handleSendDailogApi(msg, chatId, false, fullAddress, setFullAddress);
          setMsg("");
        } else {
          onChatMsgSubmit(e, msg, chatId, isFormAlreadySubmitted, setMsg);
        }
      }
    }
  }


  useEffect(() => {
    if (range) {
      setMsg(rangeData + " " + range)
      textareaRef.current.focus();
    }
  }, [rangeData, range]);

  useEffect(() => {
    if (captureFieldValue) {
      setMsg(captureFieldValue)
    }
  }, [captureFieldValue])

  return (<>

    {isBotWorking ? <div className={styles.chatbot_pill}>
      <div className={styles.all_chatpart_footer + " d-flex"} >
        <img src={emoji} alt="" onClick={handleClick} /> {/* Emoji icon to open Emoji Picker */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "left", }}
          transformOrigin={{ vertical: "top", horizontal: "right", }}
          sx={{ zIndex: "9999999999" }}
        >
          <Picker
            data={data}
            onEmojiSelect={(emoji) => handleEmojiSelect(emoji, textareaRef, setMsg, handleClose)}
            theme="light"
            emojiSize={18}
            previewPosition="none"
          />
        </Popover>

        {isAddress ? ( // Render Autocomplete for address if needed
          <Autocomplete
            disablePortal
            open={showList && msg?.length >1}
            id="select-on-focus"
            selectOnFocus
            disableClearable
            inputValue={msg}
            filterOptions={(options, state) => options}
            onChange={(event, newValue) => {
              handleSaveAddresswithpostCode(newValue, setMsg)
              setShowList(false);
            }}
            options={placesData}
            sx={{ width: 300, color: "#464749", p: 0, m: 0 }}
            getOptionLabel={(option) => option?.description}
            getOptionSelected={(option, value) =>
              option?.description === value?.description
            }
            renderOption={(props, option) => (
              <Box {...props} sx={{ padding: "0", margin: "0" }}>
                <span
                  className={styles.placeList}
                  style={{ padding: "0", margin: "0", width: "100%", borderBottom: "none", }}
                >
                  {option.description}
                </span>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                className={styles.address_input}
                placeholder="Search address ..."
                autoFocus={true}
                value={msg}
                onChange={(e) => {
                  if (!range) {
                    setMsg(e.target.value);
                    if (isAddress && e.target.value.length >1) {
                      onChangeAddress(e, messages?.slice(-1)[0]?.category);
                    } else { setShowList(false); }
                  }
                }}
                onKeyPress={(e) => { handleKeyDown(e) }}
              />
            )}
          />
        ) : (
          <input
            type="text"
            ref={textareaRef}
            name=""
            id=""
            placeholder={messages?.[messages.length - 1]?.customFieldTitle?.fieldType === "multiple-checkbox" ?
              "Please select options above." :
              messages?.[messages.length - 1]?.customFieldTitle?.fieldType === "dropdown-min-max" ?
                "Please select min & max in option above." :
                messages?.[messages.length - 1]?.customFieldTitle?.fieldType === "text-min-max" ?
                  "Please enter min & max in above fields." :
                  (messages?.[messages.length - 1]?.customFieldTitle?.fieldType === "single-radio" || messages?.[messages.length - 1]?.customFieldTitle?.fieldType === "dropdown-single") ?
                    "Please select an option above." : "Enter your message.."
            }
            value={msg}
            onChange={(e) => {
              if (!range && (!FIELD_TYPE?.includes(messages?.[messages.length - 1]?.customFieldTitle?.fieldType))) {
                setMsg(e.target.value);
              }
            }}
            onKeyPress={(e) => { handleKeyDown(e); }}
          />
        )}
        {/* Send message button */}
        <div className={styles.send_msg}
             onClick={(e) => {
              if (
                isBotWorking && // Check if bot is working
                (isAddress ? (isAddress && fullAddress) : true) &&
                (!captureFieldError) &&
                msg && // Check if message is not empty
                !stopSendloading && // Check if sending is not stopped
                messages?.[messages.length - 1]?.field !== "dailog_select" && // Check if last message is not a dialog select
                (createType !== "arrange-callback" && createType !== "property-viewing" && createType !== "schedule-valuation") // Check if not one of these types
              ) {
                if (chatClose) { // Check if chat is closing
                  handleRating(msg, setMsg, onClose, isFormAlreadySubmitted, chatId); // Handle rating
                } else {
                  if (!createChat) {
                    handleSendDailogApi(msg, chatId, false, fullAddress, setFullAddress); // Send message using API
                    setMsg(""); // Clear message input
                  } else {
                    onChatMsgSubmit(e, msg, chatId, isFormAlreadySubmitted, setMsg); // Submit chat message
                  }
                }
              }
            }}
        >
          <img className={styles.send_icon} src={sendIcondenhan} alt="" /> {/* Send icon */}
        </div>
      </div>
    </div> : <div className={styles.conversation_close}>
      This chat session has ended
    </div>}
  </>
  );
};

export default ChatbotMessageSend;
